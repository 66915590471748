.tooltip-down {
    position: relative;
    display: inline-block;
}

.tooltip-down .tooltiptext-down {
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.8);;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 15px 15px;
    margin-top: .4rem;
    margin-left: 1rem;
    position: absolute;
    z-index: 10000;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9rem;
}

.tooltip-down:hover .tooltiptext-down {
    visibility: visible;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-left {
    position: relative;
    display: inline-block;
}

.tooltip-left .tooltiptext-left {
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 15px 15px;
    margin-top: .4rem;
    margin-left: 1rem;
    position: absolute;
    z-index: 10000;
    top: -5px;
    right: 50%;
    transform: translateX(-10%);
    font-size: 0.9rem;
}

.tooltip-left:hover .tooltiptext-left {
    visibility: visible;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}