input[type="number"] {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f7f7f7;
    color: #333;
    transition: border-color 0.3s ease-in-out;
}
  
input[type="number"]:focus {
    border-color: var(--primary);
    outline: none;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--primary);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}
  
button:hover {
    background-color: var(--primary-hover);
}
  
button:focus {
    outline: none;
}
  
button:active {
    background-color: var(--primary-hover);
}

button:disabled{
    background-color: gray;
}