@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap');
:root {
    --cell-size: 32px;

    --primary: #007bff;
    --primary-hover: #0056b3;

    --background_0: #4c526583;
    --background_1: #CECECE;
    --background_2: rgba(0, 0, 0, 0.075);
}
body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:#F1F3ED;
}
