.matrix-options{
    padding-left:1rem;
    background-color: var(--background_1);
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 0px 0px 4px 4px;
}

.matrix-body {
    display:flex;
    margin-left: 2rem;
}

.matrix-container {
    position: relative;
}
  
.matrix-box {
    position: absolute;
    width: 100%;
    height: 100%;
}

.matrix-front-box {
    width: 100%;
    height: 100%;
    z-index: 999;
}