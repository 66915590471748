.view-container{
    display: flex; 
    gap: 4px 4px; 
    margin-bottom: 4px;
}
.view-background-color{
    background-color: var(--background_2);
}

.cell{
    cursor: pointer;
    position: relative;
    border-radius: 4px;
}
.cell:hover{
    box-shadow: inset 0 0 100px 100px rgba(255, 232, 232, 0.329);
}

.cell-container{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    display:flex;
    justify-content:center;
    align-content:center;
    min-height:var(--cell-size);;
    max-height:var(--cell-size);;
    min-width:var(--cell-size);;
    max-width:var(--cell-size);;
}

.cell-content{
    transform: translateY(0.2rem);
}

.cell-icon{
    font-size: 1.5rem;
}

.cell-icon-block{
    color: red;
}
.cell-icon-warning-on{
    color: rgba(255, 255, 0, 0.856);
}
.cell-icon-warning-off{
    color: rgba(255, 255, 255, 0.548);
}

.select-menu-option{
    font-size: 0.9rem;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    background-color: var(--primary);
    padding: 0.5rem;
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
}

.select-menu-option:hover{
    box-shadow: inset 0 0 100px 100px rgba(255, 232, 232, 0.329);
}

.select-menu-close{
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 4px;
}

.select-menu-close:hover{
    color: gray;
}

.select-menu-container{
    border-radius:4px;
    padding:.5rem;
    position: absolute;
    background: white;
    border: 1px solid #ccc; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}