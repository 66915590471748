.options-container{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    border-radius: 4px 4px 0 0;
    background-color: var(--background_0);
    padding:2rem;
}

.option-input-container{
    display:flex;
    flex-direction:column;
}

.option-input{
    display:flex;
    flex-wrap:wrap; 
    gap:20px 20px;
}

.options-button{
    margin-bottom: -1.2rem;
}

.info-icon{
    font-size: 1.8rem;
    max-width: 1rem;
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.7);
}

.info-icon:hover{
    color: rgba(0, 0, 0, 0.4);
}